import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

import inflowData from "../data/inflows.json";
import outflowData from "../data/outflows.json";

export const getFlowLabel = (flow) => {
  let label = "moving internally";

  switch (flow) {
    case "to":
      label = "joining";
      break;
    case "from":
      label = "leaving";
      break;
    case "retained":
    default:
      label = "moving internally";
      break;
  }

  return label;
};

export const getInflowsForIndustry = (industryName, timeframe) => {
  return orderBy(
    filter(
      inflowData,
      ({ industry, year }) =>
        industryName === industry && String(year) === String(timeframe)
    ),
    ["percentage", "rank_name"],
    ["desc", "asc"]
  );
};

export const getOutflowsForIndustry = (industryName, timeframe) => {
  return orderBy(
    filter(
      outflowData,
      ({ industry, year }) =>
        industryName === industry && String(year) === String(timeframe)
    ),
    ["percentage", "rank_name"],
    ["desc", "asc"]
  );
};
