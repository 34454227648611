import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const Select = ({
  id,
  value: selected,
  onChange,
  options,
  smaller = false,
}) => (
  <select
    id={id}
    onChange={onChange}
    value={selected}
    className={cn(styles.select, { [styles.smaller]: smaller })}
  >
    {options.map(({ value, label }) => (
      <option
        value={value}
        key={value}
        className={cn(styles.option, {
          [styles.selected]: value === selected,
        })}
      >
        {label}
      </option>
    ))}
  </select>
);

export default Select;
